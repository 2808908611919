<template>
    <v-dialog v-model="dialog" fullscreen hide-overlay>
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
                <slot></slot>
            </div>
        </template>
        <v-card class="d-flex flex-column">
            <v-card-title>
                {{ longId }}
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="d-flex flex-grow-1 pa-0">
                <iframe :src="pdfUrl" class="d-flex flex-grow-1" style="border: 0px" />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ['longId', 'pdfUrl'],
    data() {
        return {
            dialog: false
        }
    },
    watch: {}
}
</script>
